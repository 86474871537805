import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguage: BehaviorSubject<string> = new BehaviorSubject('fr')
  constructor(private translate: TranslateService) {
    translate.addLangs(['fr'])
  }

  public async setDefaultLanguage() {
    this.setLanguage('fr')
  }

  public async setLanguage(language: any) {
    this.currentLanguage.next(language)
    await this.translate.setDefaultLang(this.currentLanguage.getValue())
    await this.translate.use(this.currentLanguage.getValue())
    await localStorage.setItem('lang', this.currentLanguage.getValue())
  }

  public getLanguage(): string {
    return this.currentLanguage.getValue()
  }

  public getLongFormatLang() {
    return (
      this.currentLanguage.getValue() +
      '-' +
      this.currentLanguage.getValue().toUpperCase()
    )
  }
}
