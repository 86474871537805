import { Component } from '@angular/core'
import { LanguageService } from './services/language.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'apave-home'

  constructor(private languageService: LanguageService) {
    this.init()
  }

  private async init() {
    await this.languageService.setDefaultLanguage()
  }
}
