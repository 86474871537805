export const getRGPDLink = (language: string, linkType: string): string => {
  const links: any = {
    en: {
      legalNotice: 'https://www.sowesoft.com/en/legal-information-app-sowesign',
      dataPrivacy: 'https://www.sowesoft.com/en/data-protection-solution',
      accessibility: 'https://www.sowesoft.com/en/accessibility',
      generalTerms:
        'https://www.sowesoft.com/en/sowesign-terms-and-conditions-of-use',
      cookies: 'https://www.sowesoft.com/en/cookies-app-sowesign'
    },
    fr: {
      legalNotice: 'https://www.sowesoft.com/mentions-legales-app-sowesign',
      dataPrivacy: 'https://www.sowesoft.com/protection-des-donnees-solution',
      accessibility: 'https://www.sowesoft.com/accessibilite',
      generalTerms:
        'https://www.sowesoft.com/conditions-generales-dutilisation-de-sowesign',
      cookies: 'https://www.sowesoft.com/cookies-app-sowesign'
    }
  }
  const langLinks = links[language] || links['en']
  return langLinks[linkType]
}
